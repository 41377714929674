<template>
  <d-view title="Site Settings">
    <template v-slot:toolbar>
      <v-spacer></v-spacer>
    </template>

    <template v-slot:leftpanel>
      <v-container grid-list-lg pa-0>
        <v-layout row wrap>
          <v-flex xs12 v-if="isAuthorized('site_manager')">
            <div class="edit-text-label primary--text">Edit Site</div>
            <div
              @mouseenter="setEditSiteButtonHoveredState(true)"
              @mouseleave="setEditSiteButtonHoveredState(false)"
            >
              <v-btn
                block
                outline
                color="primary"
                class="my-0 py-0"
                data-cy="edit-site"
                :to="{ name: 'sites.edit', params: { id: activeSite.id } }"
              >
                <template class="text-uppercase caption font-weight-bold">
                  <template v-if="activeSite.name.length < 30">
                    {{ `${activeSite.name}` }}
                  </template>
                  <template v-else>
                    <MarqueeText
                      :paused="!editSiteButtonHovered"
                      :key="updateKey"
                    >
                      <div class="mx-1">
                        {{ `${activeSite.name}` }}
                      </div>
                    </MarqueeText>
                  </template>
                </template>
                <v-icon small class="ml-3">edit</v-icon>
              </v-btn>
            </div>
          </v-flex>
          <v-flex xs12>
            <installations ref="installations" :edit-mode="true" />
            <identities />
          </v-flex>
          <v-flex v-if="isAuthorized('admin')" xs12>
            <smarthubs />
          </v-flex>
        </v-layout>
      </v-container>
    </template>

    <template v-slot:map>
      <main-map
        v-if="activeSite"
        data-cy="map"
        :edit-mode="status === 'EDITING'"
        @update:latLng="$refs.installations.updateLatLng($event)"
      ></main-map>
    </template>
  </d-view>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import MarqueeText from 'vue-marquee-text-component'
const MainMap = () => import('@/components/Map/MainMap')
const Identities = () => import('@/components/Widgets/Identities')
const Installations = () => import('@/components/Widgets/Installations')
const Trackers = () => import('@/components/Widgets/Trackers')
const Smarthubs = () => import('@/components/Widgets/Smarthubs')

const props = {
  redirect: {
    type: Boolean,
    default: true
  }
}

export default {
  name: 'InstallationsView',
  props,
  components: {
    MainMap,
    Identities,
    Installations,
    MarqueeText,
    Smarthubs
  },
  computed: {
    ...mapGetters('sites', ['activeSite']),
    ...mapGetters('users', ['isAuthorized']),
    ...mapState('sentries', ['status']),
    ...mapGetters('sentries', ['sentriesLength']),
    ...mapGetters('system', ['systemSetting'])
  },
  methods: {
    setEditSiteButtonHoveredState(state) {
      this.editSiteButtonHovered = state
      this.updateKey++
    }
  },
  data: () => ({
    snackbarTimeout: null,
    editSiteButtonHovered: false,
    updateKey: 0
  }),
  mounted() {
    if (this.sentriesLength === 0 && this.redirect) {
      this.$router.push({
        name: 'installations.edit',
        params: {
          id: 'new',
          sentry: null
        }
      })
    }
    this.snackbarTimeout = setTimeout(
      () => this.$bus.$emit('SNACKBAR/EDIT_SITE'),
      3000
    )
  },
  beforeDestroy() {
    if (this.snackbarTimeout) {
      clearTimeout(this.snackbarTimeout)
      this.snackbarTimeout = null
    }
  }
}
</script>

<style>
.edit-text-label {
  font-size: x-small;
}
.outlined {
  border: 5px solid #f00;
}
</style>
