





















































































































































































































































import Vue from 'vue'
import TextDivider from '@/components/Base/TextDivider.vue'
import {
  ILocator,
  ILocatorWithName,
  ISentry
} from '@/store/modules/sentries/types'
import { mapActions, mapGetters, mapState } from 'vuex'
import { ISite } from '@/store/modules/sites/types'
import { sentryTypes } from '@/components/Mixins/constants'
import { latLngToMgrs, mgrsToLatLng } from '@/utils/utils'
import DegreeTextInput from '../Input/DegreeTextInput.vue'
import {
  convertValIfImperial,
  convertUnitIfImperial
} from '@/store/utils/index'
import {
  IInstallationFieldsState,
  InstallationFormData
} from '@/components/Installations/types'
import { normaliseCoordinateLength } from '@/utils/utils.js'
import utils from "@/components/Map/utils";

const props = {
  sentry: {
    type: Object as () => ISentry,
    default: () => {}
  },
  alertMessage: {
    type: String,
    default: ''
  },
  showAlert: {
    type: Boolean,
    default: false
  },
  errorAlert: {
    type: Boolean,
    default: false
  },
  apiCallInProgress: {
    type: Boolean,
    default: false
  },
  disableButtons: {
    type: Boolean,
    default: false
  },
  isNew: {
    type: Boolean,
    default: false
  }
}


import { FormMixin } from '@/components/Mixins'
export default Vue.extend({
  name: 'InstallationForm',
  props,
  components: { TextDivider, DegreeTextInput },
  mixins: [FormMixin],
  data(): InstallationFormData {
    return {
      sentryData: {} as ISentry,
      sentryTypes,
      locators: [],
      formValid: false,
      validMgrs: true
    }
  },
  created(): void {
    this.sentryData = Object.assign({}, this.sentry)
  },
  async mounted(): Promise<void> {
    if (!this.isNew) {
      this.locators = await this.fetchLocators(
        this.sentryData && this.sentryData.id
      )
    }
    this.$bus.$on('updateLatLng', ([id, e]) => {
      if (id === this.sentry.id) {
        this.sentryData.latitude = e.lat
        this.sentryData.longitude = e.lng
      }
    })
    this.sentryData.altitude = this.convertValIfImperial(
      this.systemSetting,
      this.sentryData.altitude,
      'm',
      2
    )
  },
  computed: {
    ...mapState('sentries', ['sentriesSet']),
    ...mapGetters('sites', ['sitesSet']),
    ...mapState(['mgrsEnabled']),
    ...mapGetters('system', ['systemSetting']),
    otherSentries(): ISentry[] {
      const sentrySet = Object.values(this.sentriesSet) as ISentry[]
      return sentrySet.filter((sentry: ISentry) => {
        return sentry.id !== this.sentry.id
      })
    },
    otherSentryNames(): string[] {
      return this.otherSentries.map((sentry: ISentry) => {
        return sentry.name
      })
    },
    name: {
      get(): string {
        return this.sentry?.name
      },
      set(val: string): void {
        this.onPropertyChange(val, 'name')
      }
    },
    siteId: {
      get(): number {
        return this.sentry?.site_id
      },
      set(val): void {
        this.onPropertyChange(val, 'site_id')
      }
    },
    sentryType: {
      get(): string {
        return this.sentry?.sentry_type
      },
      set(val): void {
        this.onPropertyChange(val, 'sentry_type')
      }
    },
    acceptLocationUpdates: {
      get(): boolean {
        return this.sentry?.accept_location_updates
      },
      set(val): void {
        const value = val != null ? val : false
        this.onPropertyChange(value, 'accept_location_updates')
        if (!value) {
          this.locator = { id: null, type: null, name: 'None' }
          this.onPropertyChange(value, 'tracked')
          this.onPropertyChange(value, 'anchored')
        }
      }
    },
    latitude: {
      get(): number {
        return utils.normaliseLatitude(this.sentry?.latitude)
      },
      set(val): void {
        this.onPropertyChange(val, 'latitude')
      }
    },
    longitude: {
      get(): number {
        return utils.normaliseLongitude(this.sentry?.longitude)
      },
      set(val): void {
        this.onPropertyChange(val, 'longitude')
      }
    },
    locator: {
      get(): ILocator {
        const { locator } = this.sentry
        return (
          (locator
            ? this.locators?.find(
                l => l.id === locator.id && l.type === locator.type
              )
            : null) || { id: null, type: null, name: 'None' }
        )
      },
      set(val): void {
        this.onPropertyChange(val, 'locator')
      }
    },
    altitude: {
      get(): number {
        return this.sentry?.altitude
      },
      set(val): void {
        this.onPropertyChange(val, 'altitude')
      }
    },
    tracked: {
      get(): boolean {
        return this.sentry?.tracked
      },
      set(val): void {
        this.onPropertyChange(val, 'tracked')
      }
    },
    trackLength: {
      get(): number {
        return this.sentry?.track_length
      },
      set(val): void {
        this.onPropertyChange(val, 'track_length')
      }
    },
    anchored: {
      get(): boolean {
        return this.sentry?.anchored
      },
      set(val): void {
        this.onPropertyChange(val, 'anchored')
      }
    },
    direction(): number {
      return this.sentry?.direction
    },
    disableField(): IInstallationFieldsState {
      switch (this.locator.type) {
        case 'RfSensor':
          return {
            mgrs: true,
            lat: true,
            lng: true,
            dir: false,
            alt: false
          }
        case 'Camera':
          return {
            mgrs: true,
            lat: true,
            lng: true,
            dir: true,
            alt: false
          }
        case 'GpsCompass':
          return {
            mgrs: true,
            lat: true,
            lng: true,
            dir: true,
            alt: true
          }
        default:
          return {
            mgrs: false,
            lat: false,
            lng: false,
            dir: false,
            alt: false
          }
      }
    },
    mgrsLocation: {
      get(): string {
        return latLngToMgrs(this.sentry.latitude, this.sentry.longitude)
      },
      set(newMgrs): void {
        try {
          const [long, lat] = mgrsToLatLng(newMgrs)
          this.validMgrs = true
          this.onPropertyChange(lat, 'latitude')
          this.onPropertyChange(long, 'longitude')
        }
        catch {
          this.validMgrs = false
        }
      }
    },
    sites(): ISite[] {
      const sites = Object.values(this.sitesSet) as ISite[]
      return sites.sort((a: ISite, b: ISite) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })
    },
    locatorItems(): ILocatorWithName[] {
      return (
        this.locators && [
          ...this.locators
        ]
      )
    }
  },
  methods: {
    ...mapActions('sentries', {
      deleteSentry: 'DELETE_SENTRY',
      fetchSentries: 'FETCH_SENTRIES',
      updateSentry: 'UPDATE_SENTRY',
      fetchLocators: 'FETCH_SENTRY_LOCATORS',
      selectSentry: 'SELECT_SENTRY'
    }),
    ...mapActions('maps', ['setCompassGraphicDirection']),
    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,
    isNameUnique(v): boolean | string {
      return !this.otherSentryNames.includes(v) || 'Duplicate installation name'
    },
    cancel(): void {
      this.$emit('cancel-installation-edit')
    },
    locatorSelect(item): ILocatorWithName {
      return item
    },
    checkLocatorValid(val) {
      if (typeof val === 'boolean') {
        if (val === true && this.locator.id > 0) {
          this.formValid = true
        }
        else if (val === true) {
          this.formValid = false
        }
        else if (val === false && this.locator.id === null) {
          this.formValid = true
        }
      }
      else if (val.id > 0 && this.locator.id > 0) {
        this.formValid = true
      }
      else {
        this.formValid = false
      }
    },
    async setDirection(direction): Promise<void> {
      this.onPropertyChange(parseFloat(direction), 'direction')
      await this.setCompassGraphicDirection(direction)
    },
    onPropertyChange(val: any, type: string): void {
      this.$emit('updateProp', {
        payload: val,
        type
      })
    },
    onSubmit(): void {
      this.$emit('onSubmit')
    },
    onDelete(): void {
      this.$emit('onDelete')
    }
  }
})
