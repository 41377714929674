
















































import Vue from 'vue'
import { ISentry } from '@/store/modules/sentries/types'
import InstallationForm from '@/components/Installations/InstallationForm.vue'
import InstallationMap from '@/components/Installations/InstallationMap.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import SimpleConfirmationDialog from '@/components/Dialogs/SimpleConfirmationDialog.vue'
import { convertValIfImperial } from '../../store/utils'
import { sentryTypes } from '@/components/Mixins/constants'
import utils from '../Map/utils'

const props = {
  id: {
    type: [String, Number],
    default: null
  },
  sentry: {
    type: Object as () => ISentry,
    default: null
  }
}

export default Vue.extend({
  name: 'InstallationSettings',
  components: { SimpleConfirmationDialog, InstallationMap, InstallationForm },
  props,
  data() {
    return {
      dialog: false,
      workingSentry: null,
      sentrySite: null,
      showDeleteDialog: false,
      alertMessage: '',
      showAlert: false,
      apiCallInProgress: false,
      disableButtons: false,
      alertError: true,
    }
  },
  created() {
    if (!this.activeSite) {
      return this.$router.replace('/installations')
    }
    if (this.isNewInstallation) {
      this.workingSentry = this.newInstallationObject
      this.sentrySite = this.activeSite
    } else {
      this.workingSentry = Object.assign({}, this.sentry)
      this.workingSentry.altitude = convertValIfImperial(
        this.systemSetting,
        this.workingSentry.altitude,
        'm',
        2
      )
      this.sentrySite = this.sitesSet[this.sentry.site_id]
    }
  },
  computed: {
    ...mapState('sentries', ['sentriesSet']),
    ...mapGetters('sites', ['sitesSet', 'activeSite']),
    ...mapGetters('system', ['systemSetting']),
    ...mapGetters('sentries', ['status', 'error', 'sentriesLength']),
    isNewInstallation() {
      return this.id === 'new'
    },
    newInstallationObject() {
      return {
        latitude: this.activeSite.latitude,
        longitude: this.activeSite.longitude,
        altitude: 0,
        accept_location_updates: false,
        tracked: false,
        direction: 0,
        name: `DroneSentry ${this.sentriesLength + 1}`,
        id: -1,
        anchored: false,
        sentry_type: sentryTypes[0].value,
        site_id: this.activeSite.id,
        locator: {
          id: null,
          name: 'None',
          type: null
        }
      }
    }
  },
  methods: {
    ...mapActions('sentries', {
      createSentry: 'CREATE_SENTRY',
      deleteSentry: 'DELETE_SENTRY',
      fetchSentries: 'FETCH_SENTRIES',
      updateSentry: 'UPDATE_SENTRY',
      fetchLocators: 'FETCH_SENTRY_LOCATORS'
    }),
    cancelEdit() {
      const to = {
        name: 'installations',
        params: {
          redirect: true
        }
      }

      if (this.sentriesLength === 0) {
        to.params = {
          redirect: false
        }
      }
      this.$router.push(to)
    },
    async onSubmit() {
      const {
        name,
        id,
        latitude,
        longitude,
        direction,
        site_id,
        accept_location_updates,
        anchored,
        tracked,
        track_length,
        sentry_type
      } = this.workingSentry || {}

      // if imperial save as metric
      let { altitude, locator } = this.workingSentry
      altitude = convertValIfImperial(this.systemSetting, altitude, 'ft', 2)
      const payload = {
        name,
        id,
        latitude,
        longitude,
        altitude,
        direction,
        site_id,
        locatable_id: locator.id,
        locatable_type: locator.type,
        accept_location_updates,
        sentry_type,
        tracked,
        track_length,
        anchored
      }

      this.apiCallInProgress = true
      if (payload.id === -1) {
        await this.createSentry(payload)
      } else {
        await this.updateSentry(payload)
      }
      this.apiCallInProgress = false

      this.showAlert = true
      if (this.status === 'OK') {
        this.alertError = false;
        this.alertMessage = `Successfully ${
          payload.id === -1 ? 'created' : 'updated'
        } installation.`
        this.disableButtons = true
        setTimeout(() => {
          this.cancelEdit()
        }, 750)
      } else {
        this.alertError = true;
        this.alertMessage = this.error
      }
    },
    async onDelete() {
      await this.deleteSentry({
        id: this.workingSentry.id
      })
      await this.fetchSentries()
      this.showDeleteDialog = false
      this.cancelEdit()
    },
    sentryPositionUpdated({ lat, lng }) {
      this.workingSentry.latitude = utils.normaliseLatitude(lat)
      this.workingSentry.longitude = utils.normaliseLongitude(lng)
    },
    updateProperty({ payload, type }): void {
      this.workingSentry[type] = payload
    }
  }
})
