











import Vue from 'vue'

const props = {
  label: {
    type: String,
    default: null
  }
}

export default Vue.extend({
  name: 'TextDivider',
  props
})
