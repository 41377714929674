<template>
  <v-text-field
    :data-cy="dataCy"
    :label="getLabel"
    :type="type"
    :class="className"
    :required="required"
    :disabled="disabled"
    :value="degreeVal"
    :name="name"
    @input="handleDirChange"
    :rules="[rules.validDegree(milsEnabled)]"
  />
</template>

<script>
import { mapState } from 'vuex'
import utils from '@/utils/utils'
import { FormMixin } from '@/components/Mixins'

const DEBOUNCE_TIMEOUT = 500

export default {
  name: 'DegreeTextInput',
  mixins: [FormMixin],
  props: {
    dataCy: String,
    degree: Number,
    label: String,
    type: String,
    className: String,
    required: Boolean,
    disabled: Boolean,
    name: String
  },
  data() {
    return {
      updateTimer: null
    }
  },
  computed: {
    ...mapState(['milsEnabled']),
    //if mil enabled return mil, otherwise return degrees
    degreeVal() {
      if (this.milsEnabled) {
        if (!this.degree) return 0
        return parseFloat(utils.degToMilsString(this.degree))
      }
      return this.degree
    },
    getLabel() {
      return this.label + `${this.milsEnabled ? ' (mil)' : ' (deg)'}`
    }
  },
  methods: {
    emitDegreeChangeEvent(val) {
      clearTimeout(this.updateTimer)
      this.updateTimer = setTimeout(() => {
        this.$emit('onDegreeChange', parseFloat(val))
      }, DEBOUNCE_TIMEOUT)
    },
    handleDirChange(value) {
      if (this.milsEnabled) {
        //check if mils is being entered, if so we convert to degrees and set the value
        if (!value) return this.emitDegreeChangeEvent(0)
        const floatMils = parseFloat(value)
        const degrees = utils.milsToDegString(floatMils)
        this.emitDegreeChangeEvent(parseFloat(degrees))
      } else {
        this.emitDegreeChangeEvent(parseFloat(value))
      }
    }
  }
}
</script>
<style scoped></style>
