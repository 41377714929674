























import Vue from 'vue'
import BaseMap from '@/components/Map/BaseMap.vue'
import { SiteMarker, SentryMarker, CompassMarker } from '../Map/Marker'
import { ISentry } from '@/store/modules/sentries/types'
import L from 'leaflet'
import { mapGetters } from 'vuex'
import { LFeatureGroup } from 'vue2-leaflet'
import { SentryMixin } from '@/components/Mixins'

const props = {
  site: {
    type: Object,
    default: () => ({
      zoom_level: 16,
      latitude: 0,
      longitude: 0
    })
  },
  sentry: {
    type: Object as () => ISentry,
    default: null
  }
}

export default Vue.extend({
  name: 'InstallationMap',
  mixins: [SentryMixin],
  props,
  components: {
    BaseMap,
    SiteMarker,
    SentryMarker,
    CompassMarker,
    LFeatureGroup
  },
  data() {
    return {
      detections: {}
    }
  },
  mounted() {
    this.$emitter.on('detections', this.processDetections)
  },
  beforeDestroy() {
    this.$emitter.off('detections', this.processDetections)
  },
  computed: {
    ...mapGetters('sentries', ['sentriesInSite']),
    otherSentries() {
      return this.sentriesInSite(this.sentry.site_id).filter(sentry => {
        return sentry.id !== this.sentry.id
      })
    },
    sentries() {
      return [...this.otherSentries, this.sentry]
    },
    sentryIcon() {
      return L.icon({
        iconUrl: require(`@/assets/sentry/green.png`),
        iconSize: [40, 40],
        iconAnchor: [20, 40]
      })
    },
    zoom(): number {
      return this.site.zoom_level || 16
    },
    siteCenter(): number[] {
      return [this.site.latitude, this.site.longitude]
    },
    sentryCenter(): number[] {
      return [this.sentry.latitude, this.sentry.longitude]
    }
  },
  methods: {
    processDetections(detections) {
      this.detections = detections
    },
    hasLocator(sentry) {
      return sentry && sentry.locator && sentry.locator['id']
    },
    markerPositionUpdated({ lat, lng }) {
      this.$emit('markerPositionUpdated', {
        lat,
        lng
      })
    },
    getCenter(sentry) {
      return [sentry.latitude || 0, sentry.longitude || 0]
    }
  }
})
